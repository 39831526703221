// import About from './components/about/About'
// import Contact from './components/Contact/Contact'
// import Experience from './components/experience/Experience'
// import Footer from './components/footer/Footer'
// import Header from './components/header/Header'
// import Nav from './components/nav/Nav'
// import Portfolio from './components/portfolio/Portfolio'
import React from 'react'
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Router } from "react-router-dom";
// import Services from './components/services/Services'
// import Testimonials from './components/testimonials/Testimonials'
import Home from './components/home/home'
import Webprojects from './components/webprojects/webprojects'
import Automation from './components/automation/automation'
import Page404 from './components/404page/404page';
import Iot from './components/iot/iot';
import Mentorship from './components/mentorship/mentorship';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route  path='/' element={<Home/>} />
        <Route  path='/webprojects' element={<Webprojects/>} />
        <Route  path='/automation' element={<Automation/>}/>
        <Route path='/iot' element={<Iot/>}/>
        <Route path='/mentorship' element={<Mentorship/>}/>
        <Route path='*' element={<Page404/>}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App