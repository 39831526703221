import {BsGithub} from "react-icons/bs"
import {BsLinkedin} from "react-icons/bs"
import { BiLogoTiktok } from "react-icons/bi";
import React from 'react'
function Headersocials() {
  return (
    <div className="header_socials"> 
        <a href='https://www.linkedin.com/in/catherline-mirobe-a741791a3/' target="_blank"><BsLinkedin/></a>
        <a href='https://github.com/Mirobecatherline' target="_blank"><BsGithub/></a>
        <a href='https://www.tiktok.com/@cate_mirobe?_t=8kTVwSrrSQY&_r=1' target="_blank"><BiLogoTiktok/></a>
    </div>
  )
}

export default Headersocials