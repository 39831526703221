import React from 'react'
import { Outlet, Link } from "react-router-dom";
function Page404(params) {
    return(
        <div>
            <div> 404 PAGE NOT Found </div>
            <Link to="/">Home</Link>
            </div>
       
    )
}
export default Page404