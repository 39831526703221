import "./footer.css"

import { BsGithub } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BiLogoTiktok } from "react-icons/bi";
import React from 'react'

function Footer() {
  return (
    <footer className="footer_container">
      <div>
        <a href="#" className="footer_icon">
          MIROBE
        </a>
      </div>
      <div>
        <ul className="permalink">
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#experience">Experience</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#portfolio">Portfolio</a>
          </li>
          <li>
            <a href="#testimonials">Testimonials</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </div>
      <div className="footer_socials">
        <div>
          {" "}
          <a
            href="https://www.linkedin.com/in/catherline-mirobe-a741791a3/"
            target="_blank"
          >
            <BsLinkedin />
          </a>
        </div>
        <div>
          <a href="https://github.com/Mirobecatherline" target="_blank">
            <BsGithub />
          </a>
        </div>
        <div>
          <a href='https://www.tiktok.com/@cate_mirobe?_t=8kTVwSrrSQY&_r=1' target="_blank"><BiLogoTiktok /></a>
        </div>
      </div>
      <div>
        <h5 className="copyright">&copy; MIROBE.All rights reserved</h5>
      </div>
    </footer>
  );
}

export default Footer