import "./header.css"

import CTA from './CTA'
import Headersocials from './Headersocials'
import me from '../../assets/me.jpg'
import me2 from '../../assets/mine.jpeg'

function Header() {
  return (
    <header>
      <div className="container header_container">
        <h5>Hello I'm</h5>
        <h1>Catherline Mirobe</h1>
        <h5 className="text-light">Software Developer  || Electrical and Electronics engineer</h5>
        <CTA/>
        <Headersocials/>
        <div className="me">
          <img src={me2} alt='me'/>
        </div>
        <a href='#contact' className='scroll_down'>scroll down</a>
      </div>
    </header>

  )
}

export default Header