import "./services.css"

import {AiOutlineCheck} from "react-icons/ai"
import React from 'react'

function Services() {
  return (
    <section id='services'>
      <h5>What i offer</h5>
      <h2>Services</h2>
      <div className="container services_container">
        
        <article className="service">
          <div className="service_head">
            <h3>Web/App development,UI/UX design</h3>
          </div>
          <ul className="service_list">
            <li><AiOutlineCheck className="service_list_icon"/><p>Creating and building custom websites from scratch</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Building and customizing CMS platforms such as WordPress to enable clients manage and update their website content.</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Building online stores and shopping platforms, including payment gateways.</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Building application programming interfaces (APIs) that allow different systems and applications to communicate.</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Using cloud platforms such as AWS to host and manage websites and web applications.</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Creating the visual look and feel of a website or app</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Using tools such as InVision or Figma to create interactive prototypes of your designs</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Providing ongoing maintenance and support for websites and web applications.</p></li>
          </ul>

        </article>
        <article className="service">
          <div className="service_head">
            <h3>IOT and ELectrical design</h3>
          </div>
          <ul className="service_list">
          <li><AiOutlineCheck className="service_list_icon"/><p>Offer end-to-end IoT solution development services</p></li>  
          <li><AiOutlineCheck className="service_list_icon"/><p> Provide services for prototyping custom electronic devices tailored to specific requirements. This might involve circuit design, PCB layout, and fabrication.</p></li>
          <li><AiOutlineCheck className="service_list_icon"/><p> Offer expertise in integrating various sensors into IoT systems.</p></li>
          <li><AiOutlineCheck className="service_list_icon"/><p> Provide solutions for wireless connectivity in IoT projects</p></li> 
          <li><AiOutlineCheck className="service_list_icon"/><p>Conducting tests and evaluations to ensure that electrical and electronic systems meet their specifications and requirements</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Managing and coordinating electrical and electronics engineering projects.</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Providing technical advice and support to clients on electrical and electronics engineering issues and challenges.</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Help clients in integrating their IoT solutions with cloud platforms like AWS,</p></li>
            
          </ul> 
        </article>
        <article className="service">
          <div className="service_head">
            <h3>Mentorship/ Training on IOT and software development </h3>
          </div>
          <ul className="service_list">
          <li><AiOutlineCheck className="service_list_icon"/><p>Comprehensive training programs covering both frontend and backend development technologies</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Workshops designed to introduce kids to the exciting world of IoT and coding</p></li> 
            <li><AiOutlineCheck className="service_list_icon"/><p>customized training programs for individuals or groups based on their specific learning objectives and skill levels</p></li>  
            <li><AiOutlineCheck className="service_list_icon"/><p>Project-based learning approaches </p></li> 
            <li><AiOutlineCheck className="service_list_icon"/><p>Collaborate with schools, community centers, and educational institutions</p></li> 
            
            {/* <li><AiOutlineCheck className="service_list_icon"/><p>Conducting surveys, interviews to understand the needs and wants of your target audience.</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Using methods such as A/B testing to gather feedback and improve the user experience.</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Organizing and structuring the content and features of a website or app to make it easy for users to navigate</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Idesigning the way that users interact with a website or app</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Creating a set of design principles and guidelines that can be used to consistently design and develop a website or app.</p></li>
            <li><AiOutlineCheck className="service_list_icon"/><p>Ensuring that your designs are accessible to users with disabilities.</p></li> */}
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services