import './experience.css';

import {HiShieldCheck} from "react-icons/hi";
import React from 'react'

function Experience() {
  return (
    <section id="experience">
      <h5>What skills i have</h5>
      <h2>My Skills</h2>
      <div className="container experience_container">
        <div className="experience_frontend">
          <h3>Software Development</h3>
          <div className="experience_content">
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>CSS</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>Javascript</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>Bootstrap,Tailwind css</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>React</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>Ruby</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>Ruby on Rails</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>PHP</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>python</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>Postgres</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>SQL</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
          </div>
        </div>
        {/* <div className="experience_backend">
          <h3>Backend Development</h3>
          <div className="experience_content">
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>Ruby</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>Ruby on Rails</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>PHP</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>python</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>Postgres</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experinece_detail">
              <HiShieldCheck className="experience_icon" />
              <div>
                <h4>SQL</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
          </div>
        </div> */}
        <div className="experience_electrical">
      <h3>IOT, Electrical and Electronics Engineering</h3>
        <div className="experience_content">
          <article className='experinece_detail'>
            <HiShieldCheck className="experience_icon"/>
            <div>
              <h4>PCB design</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>
            <article className='experinece_detail'>
            <HiShieldCheck className="experience_icon"/>
            <div>
              <h4>Circuit design</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>
            <article className='experinece_detail'>
            <HiShieldCheck className="experience_icon"/>
            <div>
              <h4>Sensor Integration</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>
            <article className='experinece_detail'>
            <HiShieldCheck className="experience_icon"/>
            <div>
              <h4>Wireless Communication Protocols</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>
            <article className='experinece_detail'>
            <HiShieldCheck className="experience_icon"/>
            <div>
              <h4>IoT Platform Integration</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>
            <article className='experinece_detail'>
            <HiShieldCheck className="experience_icon"/>
            <div>
              <h4>IoT Prototyping and Rapid Development</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>
            <article className='experinece_detail'>
            <HiShieldCheck className="experience_icon"/>
            <div>
              <h4>IoT Project Management</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>
            <article className='experinece_detail'>
            <HiShieldCheck className="experience_icon"/>
            <div>
              <h4>Circuit design</h4>
            <small className='text-light'>Experienced</small>
            </div>
            </article>
           
            <article className='experinece_detail'>
            <HiShieldCheck className="experience_icon"/>
           <div>
             <h4>solar installation and design</h4>
            <small className='text-light'>Experienced</small>
           </div>
            </article>
            
        </div>
      </div>
      </div>
    </section>
  );
}

export default Experience