import "./portfolio.css"
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import React from 'react'
import Webprojects from "../webprojects/webprojects";
import me from '../../assets/me.jpg'
import photography from "../../assets/photography.mp4";
import sales_management_system from "../../assets/sales_management_system.mp4";
import varsityelectronics from "../../assets/varsityelectronics.mp4";
import software from "../../assets/softwaredevelopment.png"
import iot from "../../assets/iot.png"
import mentor from "../../assets/mentor.png"
;
const data = [
  {
     
    id: 1,
    image: sales_management_system,
    title: "sales_management_system",
    github: "https://varsity-dd889.web.app/",
    about:
      "I created a sales management system website using React, Ruby, Synchfusion, API integration and Tailwind CSS. The website had authentication and allowed sellers to record sales, monitor revenue and profits. Throughout the project, I utilized my knowledge of these technologies to create an intuitive user experience and seamless functionality for the sales team.",
  },
  {
    id: 1,
    image: varsityelectronics,
    title: "Phones and electronics website",
    github: "https://varsityelectronicske.com/ ",
    about:
      "I designed an impressive e-commerce website using WordPress CMS, where customers could buy mobilephones and accessories. I incorporated the MPESA payment method for seamless online transactions. I incorporated WhatsApp messenger for easy customer communication. The website was visually appealing and user-friendly, demonstrating my expertise in web design and delivering high-quality projects that meet client needs.",
  },
  {
    id: 1,
    image: photography,
    title: "my project",
    github: "https://photo-7c6bd.web.app/",
    about:
      "Our photography website offers a practical platform for clients to create accounts and access their photos. Through personalized accounts, clients can conveniently view and download their images. Administrators have the ability to upload photos to different client accounts. All photos are stored in the reliable cloud storage service, Cloudinary. The website prioritizes functionality and ease of use for both clients and administrators, ensuring a straightforward and efficient experience for all users.",
  },
  // {
  //   id:2,
  //   image:me,
  //   title:"my project",
  //   github:"https",

  // },
  // {
  //   id:3,
  //   image:me,
  //   title:"my project",
  //   github:"https",

  // },
  // {
  //   id:4,
  //   image:me,
  //   title:"my project",
  //   github:"https",

  // },
  // {
  //   id:5,
  //   image:me,
  //   title:"my project",
  //   github:"https",

  // },
  // {
  //   id:6,
  //   image:me,
  //   title:"my project",
  //   github:"https",

  // }
];
function Portfolio() {
  function onclick(e) {
    e.preventDefault();
    <Link to="/webprojects/webprojects">Other Page</Link>
  }
  return (
    <section id="portfolio" >
    <h5>My projects</h5>
    <h2>Portfolio</h2>
    {/* <div className="container container_portfolio">
     {data.map((data)=>{
     return( <article className='portfolio_item' key={data.id}>
      <div className="portfolio_image">
        <video src={data.image} autoPlay loop muted controls alt="portfolio" />
      </div>
      <h3>{data.title}</h3>
      <p style={{font:'small-caption'}}>{data.about}</p>
      <a href={data.github} className='btn btn-primary' target='_blank'>Link</a>
    </article>)
     })}
      

    </div> */}
    <div  className="container container_portfolio">

      <article className='portfolio_item'>
      <div className="portfolio_image">
        <img src={software} alt="software projects" />
        {/* <video src={data.image} autoPlay loop muted controls alt="portfolio" /> */}
      </div>
      <h3>software development projects</h3>
        <p style={{font:'small-caption'}}>websites, mobile applications, USSD, payment integrations</p>
        <br />
         <Link to="/webprojects" className='btn btn-primary' >View projects</Link>
        {/* <Link to="/webprojects/webprojects.jsx">Other Page</Link> */}
      </article>

      <article className='portfolio_item'>
      <div className="portfolio_image">
        <img src={iot} alt="iot" />
        {/* <video src={data.image} autoPlay loop muted controls alt="portfolio" /> */}
      </div>
      <h3>IOT projects</h3>
        <p style={{font:'small-caption'}}>Electronics and automation projects (IOT) </p>
        <br />
         <Link to="/iot" className='btn btn-primary' >View projects</Link>
        {/* <Link to="/webprojects/webprojects.jsx">Other Page</Link> */}
      </article>

      <article className='portfolio_item'>
      <div className="portfolio_image">
        <div className="mentor">  <img src={mentor} alt="mentor"/></div>
       
        {/* <video src={data.image} autoPlay loop muted controls alt="portfolio" /> */}
      </div>
      <h3>Mentorship on software and IOT</h3>
        <p style={{font:'small-caption'}}>Mentoring others on software and automation</p>
        <br />
         <Link to="/mentorship" className='btn btn-primary' >View projects</Link>
        {/* <Link to="/webprojects/webprojects.jsx">Other Page</Link> */}
      </article>

    </div>
    </section>
  )
}

export default Portfolio