import React from 'react'
import photography from "../../assets/photography.mp4";
import sales_management_system from "../../assets/sales_management_system.mp4";
import varsityelectronics from "../../assets/varsityelectronics.mp4";  
import "./webprojects.css"
function Webprojects(params) {

    const data = [
     {
          id:1,
          image:"https://res.cloudinary.com/deyzt5ltn/video/upload/v1709454178/portfolio/maningfull_pgujjd.mp4",
          title:"Travel and Tour website",
          github:"https://meaningfultravelke.com/",
          about:
          "Travel and tours website ",
      
        },
      {
        id: 1,
        image: varsityelectronics,
        title: "E-commerce website ",
        // github: "https://varsityelectronicske.co.ke/",
        about:
          "Ecommerce website for buying products online with payment integration  ",
      },  
      
        {
          id: 1,
          image: varsityelectronics,
          title: "Phones and electronics website",
          // github: "https://varsityelectronicske.com/ ",
          about:
            "I designed an impressive e-commerce website where customers could buy mobilephones and accessories.",
        },
            {
          id: 1,
          image: photography,
          title: "Photography website",
          // github: "https://photo-7c6bd.web.app/",
          about:
            "Our photography website offers a practical platform for clients to create accounts and access their photos. ",
            },
            {
          id:2,
          image:"https://res.cloudinary.com/deyzt5ltn/video/upload/v1708519556/portfolio/zsmdmmpo41i6y1qg8xiv.mp4",
          title:"sales management system",
          github:"https://varsity-dd889.web.app/",
          about:
            "I created a sales management system. The website had authentication and allowed sellers to record sales, monitor revenue and profits.",
      
        },
        {
          id:3,
          image:"https://res.cloudinary.com/deyzt5ltn/video/upload/v1708519562/portfolio/nqkv9nep8kbuakouubx7.mp4",
          title:"USSD application with portal",
          about:
            "USSD with Mpesa integration and management portal",
      
        },
      
        {
          id:4,
          image:"https://res.cloudinary.com/deyzt5ltn/video/upload/v1708519550/portfolio/qxqwdtciq4gldwpi9gwk.mp4",
          title:"Website to buy airtimeonline",
          about:
            " Website where you can buy airtime online",
      
        },
        {
          id:1,
          image:"https://res.cloudinary.com/deyzt5ltn/video/upload/v1709453473/portfolio/ucesco_pbzmwk.mp4",
          title:"NGO website",
          github:"https://ucesco.org/",
          about:
          "NGO website that aims at helping children and the poor through donations ",
      
        },
        // {
        //   id:5,
        //   image:me,
        //   title:"my project",
        //   github:"https",
      
        // },
        // {
        //   id:6,
        //   image:me,
        //   title:"my project",
        //   github:"https",
      
        // }
      ];

    return(
       <section id="webprojects">
        <h5>Projects</h5>
        <h2>checkout my projects below</h2>
        <div  className="container container_portfolio"> 
                {data.map((data)=>{
            return( <article className='portfolio_item' key={data.id}>
                    <div className="portfolio_images">
                        <video src={data.image} autoPlay loop muted controls alt="portfolio" />
                    </div>
                    <h3>{data.title}</h3>
                    <p >{data.about}</p>
                    {data.github && (
                <a href={data.github} className='btn btn-primary' target='_blank'>Link</a>
            )}
                    </article>)})}
        </div>

       </section>
    )
}
export default Webprojects