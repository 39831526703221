import React from 'react'
import photography from "../../assets/photography.mp4";
import sales_management_system from "../../assets/sales_management_system.mp4";
import varsityelectronics from "../../assets/varsityelectronics.mp4";  
import "./iot.css"
function Iot(params) {

    const data = [
        {
          id: 1,
          image: "https://res.cloudinary.com/deyzt5ltn/video/upload/v1709112487/portfolio/fruitsorting_mfuyj8.mp4",
          title: "Fruit sorting system ",
          
          about:
            "Fruit sorting system using the fruit weight and color",
        },
        {
          id: 2,
          image: "https://res.cloudinary.com/deyzt5ltn/video/upload/v1709112505/portfolio/studloack_cxwfar.mp4",
          title: "Stud lock system ",
          
          about:
            "stud lock system with mpesa integration and a management portal ",
        },
        {
          id: 3,
          image: "https://res.cloudinary.com/deyzt5ltn/video/upload/v1709112339/portfolio/15v_qdiwck.mp4",
          title: "5V and 15v regulator",
         
          about:
            "15v and 15v regulator from 240v ",
        },
        {
          id:4,
          image:"https://res.cloudinary.com/deyzt5ltn/video/upload/v1709112177/portfolio/chaser_cct_i6oous.mp4",
          title:"Led chaser circuit",
         
          about:
            "Designing and making a led chaser circuit ",
      
      
        },
        {
          id:5,
          image:"https://res.cloudinary.com/deyzt5ltn/video/upload/v1709112498/portfolio/remotebulb_jsfbic.mp4",
          title:"Remote controlled bulb",
          
          about:
          "Remote controlled bulb  ",
        },
        // {
        //   id:5,
        //   image:me,
        //   title:"my project",
        //   github:"https",
      
        // },
        // {
        //   id:6,
        //   image:me,
        //   title:"my project",
        //   github:"https",
      
        // }
      ];

    return(
       <section id="webprojects">
        <h5>Projects</h5>
        <h2>checkout my projects below</h2>
        <div  className="container container_portfolio"> 
                {data.map((data)=>{
            return( <article className='portfolio_item' key={data.id}>
                    <div className="portfolio_images">
                        <video src={data.image} autoPlay loop muted controls alt="portfolio" />
                    </div>
                    <h3>{data.title}</h3>
                    <p >{data.about}</p>
                    {data.github && (
                <a href={data.github} className='btn btn-primary' target='_blank'>Link</a>
            )}
                    </article>)})}
        </div>

       </section>
    )
}
export default Iot