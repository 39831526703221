import './contact.css'

import{BsWhatsapp} from "react-icons/bs"
import {HiOutlineMail} from "react-icons/hi"
import React from 'react'
import {RiMessengerLine} from "react-icons/ri"
import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';

function Contact() {
  const form = useRef();
  const [stateMessage, setStateMessage] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0nlf5mv', 'template_gpwfndc', form.current, {
      publicKey: 'pLYXlG5UH3NvB05nj',
    })
      .then((result) => {
          console.log(result.text);
          setStateMessage('Message sent!');
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
      }, (error) => {
          console.log(error.text);
          setStateMessage('Something went wrong, please try again later');
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); 
      });
      e.target.reset()
  };
  return (
    <section id="contact">
      <h5>Get in Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact_container">
        <div className="contact_platform">
          <article className="contact_article">
            <div className="contact_icon">
              <HiOutlineMail />
            </div>
            <div className="contact_platform">Email</div>
            <h5 style={{ fontSize: "70%" }}>catemirobe@gmail.com</h5>
            <div>
              <a href="mailto:catemirobe@gamil.com" target="_blank">
                send a message
              </a>
            </div>
          </article>
          <article className="contact_article">
            <div className="contact_icon">
              <RiMessengerLine />
            </div>
            <div className="contact_platform">Messenger</div>
            <div className="link">catemirobe</div>
            <div>
              <a href="https://m.me/catherine.myrobz.5" target="_blank">
                send a message
              </a>
            </div>
          </article>
          <article className="contact_article">
            <div className="contact_icon">
              <BsWhatsapp />
            </div>
            <div className="contact_platform">WhatsApp</div>
            <div className="link">0793836161</div>
            <div>
              <a href="https://wa.me/793836161" target="_blank">
                send a message
              </a>
            </div>
          </article>
        </div>
        <div className="contact_form">
          <form action="" ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              name="user_name"
              placeholder="YourFullName"
              required
            />
            <input type="email" name="user_email" placeholder="YourEmail" required />
            <textarea
              name="message"
              id=""
              rows="7"
              placeholder="yourMessage"
              required
            ></textarea>
            <input type="submit" value="send message" className="btn btn-primary"/>
            {/* <button className="btn btn-primary">send Message</button> */}
            {stateMessage && <p style={{color: "green",fontSize:"20px"}}>{stateMessage}</p>}
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact