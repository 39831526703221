import React from 'react'
import photography from "../../assets/photography.mp4";
import sales_management_system from "../../assets/sales_management_system.mp4";
import varsityelectronics from "../../assets/varsityelectronics.mp4";  
import "./mentorship.css"
function Mentorship(params) {

    const data = [
        {
          id: 1,
          image: "https://res.cloudinary.com/deyzt5ltn/video/upload/v1709133777/portfolio/automation2_owonuv.mp4",
          title: "Mentorship to kids ",
          
          about:
            "Teaching automation to kids ",
        },
        {
          id: 2,
          image: "https://res.cloudinary.com/deyzt5ltn/video/upload/v1709133781/portfolio/mentorship3_osavc6.mp4",
          title: "Teaching electronics ",
          
          about:
            "Teaching electronics",
        },
        {
          id: 3,
          image: "https://res.cloudinary.com/deyzt5ltn/video/upload/v1709133777/portfolio/mentorship_rychmx.mp4",
          title: "Teaching software development integration",
          
          about:
            " Teaching on payment integration to website ",
        },
        
        {
          id:5,
          image:"https://res.cloudinary.com/deyzt5ltn/video/upload/v1709133771/portfolio/automation1_d8fyxr.mp4",
          title:"Mentorship to kids ",
          
          about:
          "Mentorship to kids ",
      },
        // },
        // {
        //   id:6,
        //   image:me,
        //   title:"my project",
        //   github:"https",
      
        // }
      ];

    return(
       <section id="webprojects">
        <h5>Projects</h5>
        <h2>checkout my projects below</h2>
        <div  className="container container_portfolio"> 
                {data.map((data)=>{
            return( <article className='portfolio_item' key={data.id}>
                    <div className="portfolio_images">
                        <video src={data.image} autoPlay loop muted controls alt="portfolio" />
                    </div>
                    <h3>{data.title}</h3>
                    <p >{data.about}</p>
                    {data.github && (
                <a href={data.github} className='btn btn-primary' target='_blank'>Link</a>)}
                    </article>)})}
        </div>

       </section>
    )
}
export default Mentorship