import "./testimonials.css"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination } from 'swiper';
import React from 'react'
import me from '../../assets/me1.jpg'
import me1 from '../../assets/logan.jpeg'
import me2 from '../../assets/ruth.jpeg'

const data = [
  {
    id: 1,
    image: me1,
    review:
      "Working with catherine was a pleasure. Their technical expertise and attention to detail made all the difference in getting our project completed on time and within budget. I highly recommend their services.",
    name: "Logan Machoka",
  },
  {
    id: 2,
    image: me2,
    review:
      "I was blown away by the quality of work that cate delivered. They not only solved the technical problems we were facing but also suggested innovative solutions that improved the user experience. I look forward to working with them again in the future.",
    name: "Pauline Rono",
  },
  {
    id: 3,
    image: me,
    review:
      "As someone who has worked with many software developers in the past, I can honestly say that cate is one of the most talented and reliable professionals I've encountered.",
    name: " Monicah Tom",
  },
];
function Testimonials() {
  return (
    <section id='testimonials'>
    <h5>Reviews from client</h5>
    <h2>Testimonials</h2>
    <Swiper className="container testimonial_container"   
     // install Swiper modules
     modules={[Pagination]}
     spaceBetween={40}
     slidesPerView={1}
     pagination={{ clickable: true }}
    >
      {data.map((data)=>{
        return  (
      <SwiperSlide className="testimonial" key={data.index}>
        <div className="clientimage">
          <img src={data.image} alt="clentimage1" className="clientimage1" />
          <h5 className='client_name'>{data.name}</h5>
          <small className="client_review">
            {data.review}
          </small>
        </div>
      </SwiperSlide>
        )
      })}
    
    </Swiper>
    </section>
  )
}

export default Testimonials