import './about.css'

import {BsFolder2Open} from "react-icons/bs"
import {FaAward} from "react-icons/fa"
import {HiOutlineUsers} from "react-icons/hi"
import React from 'react'
import me from '../../assets/me1.jpg'

function About() {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about_container">
        <div className="about_me">
          <div className="about_me_image">
            <img src={me} alt="me" />
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FaAward className="about_icon" />
              <h5>Experience</h5>
              <small>3+ year working</small>
            </article>

            <article className="about_card">
              <HiOutlineUsers className="about_icon" />
              <h5>clients</h5>
              <small>10+clients</small>
            </article>

            <article className="about_card">
              <BsFolder2Open className="about_icon" />
              <h5>Projecs</h5>
              <small>10+ completed</small>
            </article>
          </div>
          <p>
          Combining expertise in software development and electrical engineering with a focus on IoT, I'm passionate about crafting innovative solutions that enhance user experiences. 
          With extensive experience in both backend and frontend development, I thrive on staying ahead of industry trends. 
          My commitment to excellence drives me to deliver high-quality work consistently, as I continue to refine my skills in both domains.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
}

export default About